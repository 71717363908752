export enum LocalStorageKeys {
    OPENAI_KEY = "bccb8ba2-d4c7-4d15-8a69-6beb251ce1b4",
    LANGUAGE = "3c1ded33-8483-432f-a2aa-83eaa0e56681",
    GPT_VERSION = "2b203104-46d7-4b62-af8a-856baaa9f7b9",
    STATISTICS = "365106f3-9da8-46a0-b5be-f16ac77b297a",
    LAST_DECK_COUNT = "156a60d7-131f-44b8-8141-ace2ef8f2320",
    ANONYMOUS = "5b0ddaa7-6fd0-4c5c-b275-bf2d6bb28815",
    ALLOWS_COOKIES = "042d204d-01b9-4866-a53f-1ce238da5d79",
    DECK_NAME_CACHE = "a28c75b2-58dd-435a-9ca9-c153a494c5d8",
    REDUCE_ANIMATIONS = "ef6db766-1463-44ff-8b60-f417e3ca0bcd",
    LAST_SELECTED_CARD_TYPE = "f35e790c-c06d-4476-8077-5f1031cfe8d9",
    LAST_SELECTED_DECK = "e9ac1c5f-78ad-4a91-b54e-bb922e3877e5",
    PREVIOUSLY_USED_CARD_GENERATION_TOPICS = "aef452f2-1d58-42b4-a27f-7ce4f114c735"
}