import {StaticTextKeys} from "../StaticTextKeys";

const GERMAN: StaticTextKeys = {
    ABOUT_KARTAI: "Über KartAI",
    ACCEPT: "Akzeptieren",
    ADD: "Hinzufügen",
    ADDED: "Hinzugefügt",
    ADDITIONAL_PROMPT: "Zusätzlicher Fokus (Optional)",
    ADD_CARDS: "Karten hinzufügen",
    ADD_DECK: "Stapel hinzufügen",
    ADD_FOLDER: "Ordner hinzufügen",
    ADD_VARIANT: "Variante hinzufügen",
    ADVANCED_SETTINGS: "Erweiterte Einstellungen",
    AGAIN: "Nochmal",
    AI: "KI-Karten",
    AI_CARDS: "AI Karten",
    AI_DESCRIPTION: "Durch die nahtlose Verknüpfung von KartAI mit den Services von OpenAI ermöglichen wir die Nutzung der leistungsstarken künstlichen Intelligenz von KartAI zur Generierung von hochwertigen Inhalten und Lernmaterialien.",
    ALLOW_MINIMUM_EMPTY_FIELDS: "Es dürfen nicht mehr leere Felder verwendet werden, als erforderlich",
    ALL_CARDS: "Alle Karten",
    ALL_FIELDS_MUST_BE_ASSIGNED: "Alle Felder müssen zugewiesen werden",
    ALREADY_HAS_ACCOUNT: "Du hast bereits ein Konto?",
    ASSIGN_FIELDS: "Felder zuweisen",
    AUTHENTICATING: "Authentifiziere...",
    BACK: "Rückseite",
    BACK_TEMPLATE: "Template Rückseite",
    BACK_TO: "Zurück zu {name}",
    CANCEL: "Abbrechen",
    CANNOT_SCRAPE_SITE: "Die Eingegebene Webseite konnte nicht gelesen werden",
    CARD: "Karte",
    CARDS: "Karten",
    CARDS_ADDED: "{cards} Karten hinzugefügt",
    CARDS_CONTINUED: "{items} Karten fortgesetzt",
    CARDS_DELETED: "{items} Karten gelöscht",
    CARDS_GENERATED: "Karten generiert",
    CARDS_IMPORTED: "{items} Karten hinzugefügt",
    CARDS_LEARNED: "Gelernte Karten des Tages",
    CARDS_LEARNED_TOTAL: "Gelernte Karten (gesamt)",
    CARDS_MOVED: "{cards} Karten verschoben zu {deck}",
    CARDS_OPEN: "Karten offen",
    CARDS_PAUSED: "{items} Karten pausiert",
    CARDS_RESET: "Karten zurückgesetzt",
    CARDS_RESETED: "{cards} Karten zurückgesetzt",
    CARDS_SELECTED: "{cards} Karten ausgewählt",
    CARD_ADDED: "Karte hinzugefügt",
    CARD_CONTINUED: "Karte fortgesetzt",
    CARD_DELETED: "Karte gelöscht",
    CARD_GENERATION_FAILED: "Das Generieren der Karten ist fehlgeschlagen. Bitte Versuche es erneut",
    CARD_GENERATION_FAILED_STEP: "Das Generieren der Karten  ist nach {chars} Zeichen ist fehlgeschlagen. Setze generieren fort...",
    CARD_GENERATION_PROMPT: "Du erstellst Karteikarten des Kartentypen {cardType} im folgenden lesbaren JSON-Format: {eg}. Beziehe dich beim generieren immer auf das gegebene Material des Users. Achte besonders darauf, du nur das JSON zurück gibst, damit es richtig geparsed werden kann.",
    GEMINI_CARD_GENERATION_PROMPT: "Kartentyp: {cardType}, Felder: {fields}, Material: {material}",
    CARD_MOVED: "Eine Karte verschoben zu {deck}",
    CARD_PAUSED: "Karte pausiert",
    CARD_RESET: "Karte zurückgesetzt",
    CARD_RESETED: "Karte zurückgesetzt",
    CARD_SELECTED: "1 Karte ausgewählt",
    CARD_TEMPLATE: "Kartenvorlage",
    CARD_TYPE: "Kartentyp",
    CARD_TYPES: "Kartentypen",
    CARD_TYPE_ADDED: "Kartentyp hinzugefügt",
    CARD_TYPE_CHANGED: "Kartentyp geändert",
    CARD_TYPE_CREATED: "Kartentyp erstellt",
    CARD_TYPE_DELETED: "Kartentyp gelöscht",
    CARD_TYPE_VARIANT_ADDED: "Kartenvariante hinzugefügt",
    CHANGES_SAVED: "Änderungen gespeichert",
    CHANGE_CARD_TYPE: "Kartentyp ändern",
    CHANGE_TO_CARD_TYPE: "Ändern zu (Kartentyp)",
    CHINESE: "Chinesisch",
    KARTAI_CLOUD: "KartAI-Cloud",
    KARTAI_CLOUD_DESCRIPTION: "Die automatische Speicherung in der KartAI-Cloud gewährleistet, dass deine Karten stets synchronisiert sind und auf all deinen Geräten in vollem Umfang verfügbar sind, unabhängig von Ort oder Zeitpunkt.",
    CONFIRM: "Bestätigen",
    CONFIRM_CHANGE_CARD_TYPE: "Möchtest du den Kartentyp wirklich ändern? Die Felder: {fields} werden durch die Änderung gelöscht.",
    CONFIRM_PASSWORD: "Passwort bestätigen",
    CONFORM_PASSWORD: "Passwort bestätigen",
    CONTENT: "Inhalt",
    CONTINUE: "Fortfahren",
    CONTINUE_CARD: "Karte fortsetzen",
    CONTINUE_CARDS: "Karten fortsetzen ({items})",
    CONTINUE_CARDS_TEXT: "Möchtest du die ausgewählten Karten fortsetzen?",
    CONTINUE_CARD_TEXT: "Möchtest du die Karte fortsetzen?",
    CONTINUE_SELECTED: "Ausgewählte fortsetzen",
    CONTINUE_WITHOUT_SIGNING_IN: "Ohne Anmeldung fortfahren",
    CONTINUE_WITH_GOOGLE: "Mit Google fortfahren",
    COOKIE_DESCRIPTION: "Wir verwenden Cookies, um Ihnen das bestmögliche Erlebnis zu bieten. Sie ermöglichen es uns auch, das Nutzerverhalten zu analysieren, um die Website ständig für dich zu verbessern.",
    COOKIE_SETTINGS: "Cookie Einstellungen",
    CORRECT: "Richtig",
    COULD_NOT_PARSE_CSV: "In dieser CSV konten keine Daten gelesen werden. Bitte überprüfe der Format der Datei.",
    MARKDOWN_DOCUMENT_LINK: "Markdown Dokumenten Link",
    MARKDOWN_IMPORT_FAILED: "Das Importieren der Tabelle ist fehlgeschlagen.",
    MARKDOWN_TABLE: "Markdown Tabelle",
    CREATE_DECK: "Stapel erstellen",
    CSV_DELIMITER: "CSV-Separator",
    CSV_FIELDS: "CSV Felder",
    CSV_FORMAT: "Karten im CSV Format (.csv)",
    CSV_SUCCESSFULLY_PARSED: "{items} Zeilen wurden erfolgreich gelesen.",
    CUSTOMIZATION_DESCRIPTION: "KartAI ermöglicht freie Anpassungen. Du kannst Karten nach Belieben bearbeiten und verschiedene Kartentypen und Varianten erstellen.",
    CUSTOMIZATION_OPTIONS: "Individualität",
    CUSTOM_STUDY: "Freies lernen",
    CUSTOM_STUDY_EXPLANATION: "Hinweis: Im Modus 'Freies lernen' hast du die Möglichkeit deine Karten unabhängig von aktuellen Lernzyklus zu wiederholen.",
    CUSTOM_STUDY_GENERAL_TEXT: "Du hast bereits alle Karten gelernt. Möchtest du im Modus 'Freies lernen' fortfahren?",
    CUSTOM_STUDY_MODAL_TEXT: "Du hast bereits alle Karten in {dir} gelernt. Möchtest du im Modus 'Freies lernen' fortfahren?",
    CUSTOM_STUDY_TEXT: "Wenn Sie außerhalb des regulären Zeitplans lernen möchten, können Sie die Funktion 'freies Lernen' verwenden.",
    DECK: "Stapel",
    DECKS: "Stapel",
    DECKS_DELETED: "Stapel gelöscht",
    DECKS_IMPORTED: "{num} Stapel importiert",
    DECKS_MOVED_TO: "Stapel verschoben zu {dir}",
    DECK_ADDED: "Stapel hinzugefügt",
    DECK_ALREADY_EXISTS: "Dieser Stapel existiert bereits ({deck})",
    DECK_DELETED: "Stapel gelöscht",
    DECK_EXCEEDS_LIMIT: "Der Stapel {deck} hat mehr als die erlaubten {size} Karten",
    DECK_FINISHED: "Herzlichen Glückwunsch! Dieser Stapel ist vorerst geschafft.",
    DECK_HEADLINE: "Deine Stapel",
    DECK_IMPORTED: "Stapel importiert",
    DECK_MOVED_TO: "Ordner verschoben zu {dir}",
    DECK_NAME: "Stapelname",
    DECK_SHARED: "Stapel veröffentlicht",
    DECK_TOO_MANY_CARDS: "Dieser Stapel hat zu viele Karten um veröffentlicht zu werden (Maximal ({cards}) Karten)",
    DECK_UNSHARED_TEXT: "Der Stapel wird nun nicht mehr geteilt",
    DECK_VIEW: "Stapelübersicht",
    DECLINE: "Ablehnen",
    DEFAULT: "Standard",
    DEFAULT_CARD_TYPES_CANNOT_BE_MODIFIED: "Standard Kartentypen können nicht bearbeitet werden",
    DEFAULT_CARD_TYPE_NAME: "Einfach (Standard)",
    DEFAULT_TEXTFIELD_CARD_TYPE_NAME: "Einfach (Antwort eintippen)",
    DELETE: "Löschen",
    DELETE_ACCOUNT: "Konto löschen",
    DELETE_ACCOUNT_TEXT: "Möchtest du dein Konto löschen?",
    DELETE_ACCOUNT_WARNING_TEXT: "Hinweis: Mit dem Löschen deines Kontos werden alle auf KartAI gespeicherten Inhalte von dir gelöscht",
    DELETE_CARD: "Karte löschen",
    DELETE_CARDS: "Karten löschen",
    DELETE_CARDS_TEXT: "Möchtest du die ausgewählten Karten löschen?",
    DELETE_CARD_TEXT: "Möchtest du die Karte löschen?",
    DELETE_CARD_TYPE: "Kartentyp löschen?",
    DELETE_CARD_TYPE_TEXT: "Möchtest du den Kartentyp löschen?",
    DELETE_DECK: "Stapel löschen?",
    DELETE_DECKS: "Stapel löschen ({items})",
    DELETE_DECKS_TEXT: "Möchtest du die ausgewählten Stapel löschen?",
    DELETE_DECK_TEXT: "Möchtest du den Stapel '{name}' löschen?",
    DELETE_FOLDER: "Ordner löschen",
    DELETE_FOLDERS: "Ordner löschen ({items})",
    DELETE_FOLDERS_TEXT: "Möchtest du die ausgewählten Ordner löschen?",
    DELETE_FOLDER_TEXT: "Möchtest du den '{name}' Ordner löschen?",
    DELETE_ITEMS: "Elemente löschen ({items})",
    DELETE_ITEMS_TEXT: "Möchtest du die ausgewählten Elemente löschen?",
    DELETE_VARIANT: "Variante löschen",
    DELETE_VARIANT_TEXT: "Möchtest du die Kartenvariante löschen?",
    DOWNLOAD: "Herunterladen",
    DOWNLOADS: "Downloads",
    DOWNLOAD_CSV: ".csv herunterladen",
    DOWNLOAD_DECK: "Stapel herunterladen",
    DOWNLOAD_FORMAT: "Download Format",
    DUE_DATE: "Fälligkeitsdatum",
    DUPLICATE_DECK: "Importieren fehlgeschlagen: Doppelte Stapel ({deck})",
    EACH_FIELD_CAN_ONLY_BE_ASSIGNED_TO_ONE_OTHER_FIELD: "Jedes Feld darf nur einem anderen Feld zugewiesen werden",
    EASY: "Einfach",
    EDIT: "Bearbeiten",
    EDIT_CARD: "Karte bearbeiten",
    EDIT_CARD_TEMPLATE: "Kartenvorlage bearbeiten",
    EDIT_CARD_TYPE: "Kartentyp bearbeiten",
    EDIT_DECK: "Stapel bearbeiten",
    EDIT_FIELDS: "Felder bearbeiten",
    EG: "z.B.",
    ELEMENTS_DELETED: "{items} Elemente gelöscht",
    ELEMENT_UNSHARED: "Element nicht mehr geteilt",
    EMAIL: "Email",
    EMAIL_ALREADY_IN_USE: "Diese E-Mail-Adresse wird bereits verwendet.",
    EMPTY: "Leer",
    EMPTY_DECKS_CANNOT_BE_PUBLISHED: "Leere Stapel können nicht veröffentlicht werden",
    EMPTY_FOLDER: "Dieser Ordner ist leer.",
    EMPTY_FOLDERS_CANNOT_BE_PUBLISHED: "Leere Ordner können nicht veröffentlicht werden",
    ENGLISH: "Englisch",
    ENTER_ANSWER: "Antwort eingeben",
    EVALUATE_CARDS: "Karten evaluieren",
    EXPORT: "Exportieren",
    EXPORT_DECK: "Stapel exportieren",
    EXPORT_FORMAT: "Export Format",
    FAILED_TO_GENERATE_CARDS: "Das Generieren der Karten ist fehlgeschlagen. Bitte versuche später es erneut",
    FEATURES: "Features",
    FIELD: "Feld",
    FIELDS: "Felder",
    FIELD_CONTENT_TOO_LONG: "Der Inhalt des Feldes '{field}' ist zu lang (Maximal {limit} Zeichen)",
    FIELD_EMPTY: "Es müssen alle Felder ausgefüllt sein",
    FIELD_NAME: "Name des Feldes",
    FILE_TOO_BIG: "Die Datei '{name}' ist größer als {size}MB",
    FLASHCARDS: "Karteikarten",
    FOLDERS_DELETED: "Ordner gelöscht",
    FOLDERS_MOVED_TO: "Ordner verschoben zu {dir}",
    FOLDER_ADDED: "Ordner hinzugefügt",
    FOLDER_DELETED: "Ordner gelöscht",
    FOLDER_MOVED_TO: "Ordner verschoben zu {dir}",
    FOLDER_NAME: "Ordner Name",
    FOLDER_NO_CARDS: "Dieser Ordner hat aktuell keine Karten",
    FOLDER_SHARED: "Ordner veröffentlicht",
    FOLDER_TOO_MANY_CARDS: "Dieser Ordner hat zu viele Karten um veröffentlicht zu werden (Maximal ({cards}) Karten)",
    FORGET: "Vergessen",
    FORGET_CARD: "Karte vergessen",
    FORGET_CARDS: "Karten vergessen ({items})",
    FORGET_CARDS_TEXT: "Möchtest du die ausgewählten Karten vergessen?",
    FORGET_CARD_TEXT: "Möchtest du die Karte vergessen?",
    FRENCH: "Französisch",
    FRONT: "Vorderseite",
    FRONT_TEMPLATE: "Template Vorderseite",
    FUNCTION_TEMPORARILY_DISABLED: "Diese Funktion ist temporär deaktiviert",
    GENERATE_CARDS: "Karten generieren",
    GERMAN: "Deutsch",
    GETTING_STARTED: "Kostenlos starten!",
    GOOD: "Gut",
    GO_BACK: "Zurück",
    GPT: "GPT",
    GPT_SETTINGS: "GPT-Einstellungen",
    GPT_VERSION: "GPT-Version",
    HARD: "Schwer",
    HOMEPAGE: "Startseite",
    HOME_MENU: "Start",
    IMPORT: "Importieren",
    IMPORTED_NAME: "{name} wurde importiert",
    IMPORT_CARDS_TEXT: "Möchtest du den öffentlichen Stapel '{name}' mit {cards} Karten importieren?",
    IMPORT_CSV: "CSV-Datei importieren",
    IMPORT_DECK: "Stapel importieren",
    IMPORT_LIMIT: "Es können maximal {items} Karten gleichzeitig importiert werden",
    INDEPENDENT_STUDY_MODE: "Freier Lernmodus",
    INFORMATION: "Informationen",
    INITIALIZING: "Initialisiere...",
    INPUT_TOO_LONG: "Die Eingabe ist zu lang (Maximal {chars} Zeichen)",
    INVALID_APIKEY: "Der API Schlüssel ist ungültig",
    INVALID_CARD_INFO: "Diese Karte wurde fehlerhaft gespeichert. Bitte lösche sie und erstelle sie erneut.",
    INVALID_EMAIL: "Die eingegebene E-Mail-Adresse ist ungültig. ",
    INVALID_FILE_FORMAT: "Die Format der Datei '{name}' ist fehlerhaft",
    INVALID_LOGIN_CREDENTIALS: "Die eingegebenen Zugangsdaten sind ungültig. ",
    ITEMS_MOVED_TO: "{items} Elemente verschoben zu {dir}",
    ITEMS_SUCCESSFULLY_IMPORTED: "{items} Elemente erfolgreich importiert",
    ITEM_SUCCESSFULLY_IMPORTED: "1 Element erfolgreich importiert",
    JUST_NOW: "Gerade eben",
    KARTAI_DECK_PACKAGE: "KartAI-Stapelpaket (.kpkg)",
    KARTAI_DESCRIPTION: "KartAI ist eine intelligente Karteikarten-App, die das Lernen leichter macht. Mit KartAI kannst du effizienter lernen und mehr Wissen aufnehmen. Dank unseren effizienten Algorithmen kannst du deine Lernzeit minimieren. KartAI basiert auf spaced repetition, einer bewährten Lernstrategie, die Ihr Gedächtnis gezielt stärkt. KartAI kann benutzt werden um: ",
    KNOWN: "Gewusst",
    LANGAUGE_SETTINGS: "Spracheinstellungen",
    LANGUAGE: "Sprache",
    LEARN: "Lernen",
    LEARNED_TIME: "Gelernte Zeit",
    LEARNING_LEVEL: "Lernstufe",
    AVERAGE_LEARNING_LEVEL: "Durchschnittliche Lernstufe",
    LEARN_ALL: "Alle lernen",
    LEARN_DECK: "Stapel lernen",
    LEARN_FOLDER: "Ordner lernen",
    LEGAL_NOTICE: "Impressum",
    LOADING: "Lädt...",
    LOADING_CARDS: "Karten werden geladen...",
    LOADING_CARD_TYPES: "Kartentypen werden geladen...",
    LOADING_DECKS: "Stapel werden geladen...",
    LOCATION: "Ort",
    LOGIN_TO_CONTINUE: "Melde dich an, um fortzufahren",
    MANAGE_CARDS: "Karten verwalten",
    MATERIAL: "Material",
    MAXIMUM_CARDS_REACHED: "Die maximale Kartenanzahl in diesem Stapel wurde erreicht ({size})",
    MAX_DECKS_REACHED: "Die maximale Anzahl an Stapeln wurde erreicht",
    MAX_FOLDERS_REACHED: "Die maximale Anzahl an Ordnern wurde erreicht",
    MAX_IMPORT_SIZE: "Die maximale Importmenge liegt bei {items}",
    MOVE: "Verschieben",
    MOVE_CARD: "Karte verschieben",
    MOVE_CARDS: "Karten verschieben ({items})",
    MOVE_DECK: "Stapel verschieben",
    MOVE_DECKS: "Stapel verschieben ({items})",
    MOVE_FOLDER: "Ordner verschieben",
    MOVE_FOLDERS: "Ordner verschieben ({items})",
    MOVE_ITEMS: "Elemente verschieben ({items})",
    MOVE_TO_ANOTHER_DECK: "In anderen Stapel verschieben",
    MOVE_TO_DECK: "Verschieben in (Stapel)",
    MOVE_TO_FOLDER: "Verschieben in (Ordner)",
    NAME: "Name",
    NEW: "Neu",
    NEW_CARD: "Neue Karte",
    NEW_CARD_TYPE: "Neuer Kartentyp",
    NEW_DECK: "Neuer Stapel",
    NEW_FOLDER: "Neuer Ordner",
    NEW_VARIANT: "Neue Variante",
    NEXT_LEARNING_CARD: "Die nächste zu lernende Karte wird in {duration} bereitgestellt.",
    NEXT_PAGE: "Nächste Seite",
    NOT_ENOUGH_INPUT_TEXT_FILE: "Das hochgeladene Datei konnte nicht richtig gelesen werden oder enthält zu wenig Text",
    NOT_ENOUGH_INPUT_TEXT: "Der eingegebene Text ist zu kurz. Bitte füge mehr Text hinzu.",
    NO_ACCOUNT: "Du hast noch kein Konto?",
    NO_API_CONNECTION: "Kein Verbindung zur API",
    NO_API_CONNECTION_TEXT: "Es tut uns leid, aber wir konnten keine Verbindung zur API herstellen. Bitte überprüfe deine Internetverbindung und versuche es erneut. Wenn das Problem weiterhin besteht, könnte die API vorübergehend nicht verfügbar sein.",
    NO_CARDS: "Dieser Stapel hat aktuell keine Karten",
    NO_CARDS_GENERAL: "Es existieren aktuell keine Karten",
    NO_CARD_TO_LEARN: "Es sind aktuell keine Karten offen zu lernen",
    NO_CARD_TYPES: "Es existieren aktuell keine benutzerdefinierten Kartentypen",
    NO_DECKS: "Es existieren aktuell keine Stapel",
    NO_FILE_UPLOADED: "Es wurde keine Datei hochgeladen",
    NO_FOLDER_TO_MOVE_IN: "Es gibt keinen Ordner in den die ausgewählten Element verschoben werden können.",
    NO_MOBILE_SUPPORT: "Die Website unterstützt keine Mobilgeräte",
    NO_OPENAI_API_KEY: "Es ist kein OpenAI API-Schlüssel hinterlegt",
    NO_PUBLIC_DECKS: "Es existieren aktuell keine öffentlichen Stapel",
    NUM_FIELDS: "Anzahl an Feldern",
    NUM_OF_COLUMNS_IN_TABLE: "Anzahl der Spalten in der Tabelle",
    NUM_OF_COLUMNS_TOO_BIG: "Die Anzahl der Spalten darf maximal 6 sein",
    NUM_OF_COLUMNS_TOO_SMALL: "Die Anzahl der Zeilen muss mindestens 2 sein",
    ONE_TEXT_FIELD_ALLOWED: "Es ist nur ein Textfeld pro Karte erlaubt",
    OPENAI_KEY: "Openai API Schlüssel",
    OPENAI_SETTINGS: "OpenAI Einstellungen",
    OPEN_DECK: "Stapel öffnen",
    OPEN_IN_NEW_TAB: "In neuem Tab öffnen",
    OPTIONS: "Optionen",
    OR: "oder",
    OWNER: "Besitzer",
    PAGE: "Seite",
    PASSWORD: "Passwort",
    PASSWORDS_DO_NOT_MATCH: "Die eingegebenen Passwörter stimmen nicht überein. ",
    PAUSE: "Pausieren",
    PAUSE_CARD: "Karte pausieren",
    PAUSE_CARDS: "Karten pausieren ({items})",
    PAUSE_CARDS_TEXT: "Möchtest du die ausgewählten Karten pausieren?",
    PAUSE_CARD_TEXT: "Möchtest du die Karte pausieren?",
    PAUSE_SELECTED: "Ausgewählte pausieren",
    PLEASE_SELECT_DECK: "Bitte wähle einen Stapel aus",
    PLEASE_SET_OPENAI_KEY: "Bitte hinterlege einen OpenAI-Key",
    PREVIEW: "Vorschau",
    PREVIOUS_PAGE: "Vorherige Seite",
    PRIVACY_POLICY: "Datenschutzerklärung",
    PROFILE: "Profil",
    PROMPT: "Prompt",
    PUBLIC_DECKS: "Öffentliche Stapel",
    PUBLISH: "Veröffentlichen",
    PUBLISH_DECK: "Stapel veröffentlichen",
    PUBLISH_DECK_NOTE: "Hinweis: Wenn du den Stapel veröffentlichst, kann jeder Benutzer von KartAI die Karten dieses Stapels einsehen, aber nicht bearbeiten.",
    PUBLISH_DECK_TEXT: "Möchtest du den Stapel veröffentlichen",
    PUBLISH_FOLDER: "Ordner veröffentlichen",
    PUBLISH_FOLDER_NOTE: "Hinweis: Wenn du den Ordner veröffentlichst, kann jeder Benutzer von KartAI die Karten dieses Ordner einsehen, aber nicht bearbeiten.",
    PUBLISH_FOLDER_TEXT: "Möchtest du den Ordner veröffentlichen",
    READ_MARKDOWN_TABLE: "Markdown Tabelle lesen",
    READ_CSV: "CSV-Daten lesen",
    REDUCE_ANIMATIONS: "Animationen reduzieren",
    REGENERATE_CARDS: "Karten neu generieren",
    RELOAD: "Neu laden",
    REMOVE: "Entfernen",
    RENAME: "Umbenennen",
    RENAMED_DECK: "Stapel umbenannt",
    RENAMED_DIRECTORY: "Ordner umbenannt",
    RENAME_DECK: "Stapel umbenennen",
    RENAME_FOLDER: "Ordner umbenennen",
    RENAME_VARIANT: "Variante umbenennen",
    REQUEST_ERROR: "Es gab einen Fehler beim bearbeiten deiner Anfrage. Bitte lade sie Seite neu und versuche es erneut.",
    REVIEW: "Wiederholen",
    ROWS_PER_PAGE: "Zeilen pro Seite",
    SAMPLE_CARDS: "Beispiel Karten",
    SAVE: "Speichern",
    SEARCH: "Suchen",
    SETTINGS: "Einstellungen",
    SETTINGS_SAVED: "Einstellungen gespeichert",
    SET_OPENAI_KEY: "Openai API Schlüssel hinterlegen",
    SHOW_ANSWER: "Antwort anzeigen",
    SHOW_MORE: "Mehr anzeigen",
    SIGNED_IN_AS: "Du bist angemeldet als",
    SIGN_IN: "Anmelden",
    SIGN_IN_GOOGLE: "Mit Google anmelden",
    SIGN_IN_GUEST: "Als Gast anmelden",
    SIGN_OUT: "Abmelden",
    SIGN_UP: "Registrieren",
    SIMPLE_BOTH_DIRECTIONS: "Einfach (Beide Richtungen)",
    SLOGAN: "Wissen ordnen, Zukunft formen",
    STATISTICS: "Statistiken",
    STORAGE_LIMIT: "Fehler! Die hast die maximale Speicherkapazität von {items} Karten erreicht.",
    STORAGE_LIMIT_DECK: "Fehler! Die hast die maximale Speicherkapazität von {items} Karten in einen Stapel erreicht.",
    STORAGE_USAGE: "Speicherauslastung",
    SUB_DIRS_DECKS: "Unterordner / Stapel",
    SYNC: "Synchronisieren",
    SYNC_INFO_TEXT: "Die Karten werden synchronisiert. Bitte verlasse nicht die Seite um die Synchronisation nicht zu unterbrechen. (Geschätzte Dauer: {duration}s)",
    TABLE_FIELDS: "Tabellenfelder",
    TEMPLATE: "Template",
    TEXT_FIELDS_INPUT_NOT_ALLOWED: "Textfelder sind als Karteneingabe nicht erlaubt",
    TOO_MANY_DECKS: "Zu viele Stapel. Es existieren bereits {cCount} von {size} Stapeln.",
    TOPIC: "Thema",
    UNKNOWN: "Nicht gewusst",
    UNSHARE: "Nicht mehr teilen",
    UNSHARE_DECK: "Stapel nicht mehr teilen?",
    UNSHARE_DECK_TEXT: "Möchtest du aufhören den Stapel zu teilen?",
    UNSHARE_FOLDER: "Ordner nicht mehr teilen?",
    UNSHARE_FOLDER_TEXT: "Möchtest du aufhören den Ordner zu teilen?",
    UNSHARE_ITEM: "Element nicht mehr teilen",
    UNSHARE_ITEM_TEXT: "Möchtest du die Veröffentlichung des Elements rückgängig machen?",
    UPLOAD_DECK: "Stapel hochladen",
    UPLOAD_FILE: "Datei hochladen",
    UPLOAD_IMAGE: "Bild hochladen",
    URL: "Internet Link",
    USER: "Benutzer",
    USE_CUSTOM_MATERIAL: "Eigenes Material verwenden",
    USE_IMAGE: "Bild benutzen",
    USE_IMAGE_S: "Bild(er) benutzen",
    USE_PDF: "Pdf benutzen",
    VARIANT: "Variante",
    VARIANT_CREATED: "Variante erstellt",
    VARIANT_DELETED: "Kartenvariante gelöscht",
    VARIANT_NAME: "Varianten Name",
    WEAK_PASSWORD: "Das Passwort ist zu schwach. Bitte wähle ein sichereres Passwort.",
    X_OUT_OF_Y_CARDS: "({x} / {y} Karten)",
    YOUR_CARD_TYPES: "Deine Kartentypen",
    "gpt-3.5-turbo": "gpt-3.5-turbo",
    "gpt-4": "gpt-4 (Teuer)",
    SUB_SLOGAN: "KartAI ist die Essenz des Lernens, jede Karte ist ein Schritt zum Wissen. Organisiere, lerne, beherrsche – Karte für Karte, Richtung Zukunft.",
    KARTAI_WEB: "KartAI Web",
    EFFICIENT_LEARNING_ALGORITHMS_FEATURE: "Lernalgorithmen",
    EFFICIENT_LEARNING_ALGORITHMS_FEATURE_TEXT: "Unser Algorithmus identifiziert anhand der Vergessenskurve Inhalte, die sich an der Schwelle des Vergessens befinden könnten, und präsentiert dir diese gezielt. So unterstützen wir dich dabei, wichtige Informationen in deinem Gedächtnis zu bewahren und dem Vergessen zu entgehen.",
    ALL_RIGHTS_RESERVED: "Alle Rechte vorbehalten",
    ICONS_BY: "Icons von",
    BY: "von",
    IMPORT_MARKDOWN_TABLE: "Markdown Tabelle importieren",
    DOWNLOAD_BUTTON_LABEL: "KartAI herunterladen",
    DOWNLOAD_FOR_WINDOWS: "Für Windows herunterladen",
    DOWNLOAD_FOR_MAC: "Für Mac herunterladen",
    DOWNLOAD_SECTION_TITLE: "KartAI herunterladen",
    PRIVACY_POLICY_INFO: "Mit der Registrierung stimmst du unserer Datenschutzerklärung zu: ",
    DOWNLOAD_FOR_MAC_DESCRIPTION: "Benutze KartAI auf deinem Mac und genieße die Vorteile der intelligenten Karteikarten-App.",
    DOWNLOAD_FOR_WINDOWS_DESCRIPTION: "Lade KartAI für Windows herunter und profitiere von den Vorteilen der intelligenten Karteikarten-App.",
    TABLE_HEAD: "Hat die Markdown Tabelle eine Kopfzeile?",
    DUE: "Fällig",
    DUE_IN: "Fällig in",
    DUE_NOW: "Jetzt fällig",
    NEWS: "Neuigkeiten",
    FAILED_TO_EXTRACT_TEXT: "Der Text konnte nicht extrahiert werden",
    LEARNING_STATE_INFO: "Die Lernstufe ist ein Maß den Lernfortschritt einer Karte. Eine Lernstufe von 0 bedeutet, dass die Karte noch nicht gelernt wurde. Die Lernstufe steigt mit jeder erfolgreichen Wiederholung der Karte. Bei einer Lernstufe von 10 gilt die Karte als vollständig gelernt und sie ist im Langzeitgedächtnis gespeichert.",
    CARD_TYPE_CARD_DELETE_CASCADE: "Möchtest du den Kartentyp löschen? Durch das Löschen des Kartentyps werden alle {num} Karten dieses Typs gelöscht",
    HEADLINE: "Überschrift",
}

export default GERMAN
